import * as React from 'react';
import {useEffect} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const AlertDialog = ({title, content, onConfirm, onCancel}) => {
    const [open, setOpen] = React.useState(false);
    useEffect(() => {
        setOpen(true);
    }, []);
    return (<Dialog
        open={true}
        onClose={onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {title}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {content}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={onCancel}>Hủy bỏ</Button>
            <Button onClick={onConfirm} autoFocus>
                Đồng ý
            </Button>
        </DialogActions>
    </Dialog>);
};

export default AlertDialog;
