import React from 'react';
import './App.css'
import Home from "./Container/Home";
import QuaySo from "./Container/QuaySo";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import './antd.css'

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Home/>}/>
                <Route path="/quay-so" element={<QuaySo/>}/>
            </Routes>
        </BrowserRouter>
    )
};

export default App;
