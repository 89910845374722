import styled from 'styled-components';
import { Select } from 'antd';

const SelectOption = styled(Select)`
  width: 100%;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  z-index: 10;

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    background: white;
    border: 1px solid #151357;
    box-sizing: border-box;
  }

  &.ant-select-single .ant-select-selector .ant-select-selection-item,
  &.ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    //padding: 4px;
    color: black;
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    //padding: 0px 5px;
  }
`;
export { SelectOption };
