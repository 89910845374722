import styled from 'styled-components';
import {Input} from 'antd';

const InputText = styled(Input)`
  background: white;
  box-sizing: border-box;
  border: 1px solid #151357;


  &:where(.css-dev-only-do-not-override-ed5zg0) .ant-input-group .ant-input-group-addon {
    border-radius: 0;
  }


  .ant-input {
    border-radius: 0 !important;
  }

  ::-webkit-input-placeholder,
  :-ms-input-placeholder,
  ::placeholder {
  }
`;
const InputPasswordText = styled(Input.Password)`
  background: white;

  box-sizing: border-box;
  border: 1px solid #151357;

  &:where(.css-dev-only-do-not-override-ed5zg0) .ant-input-group .ant-input-group-addon {
    border-radius: 0;
  }

  &.ant-input-affix-wrapper ,.css-dev-only-do-not-override-ed5zg0 {
    border-radius: 0 !important;
  }
  


  .ant-input {
    border-radius: 0 !important;
  }

  &:where(.css-dev-only-do-not-override-ed5zg0).ant-input-affix-wrapper {
    border-radius: 0 !important;
  }

  &.ant-input-affix-wrapper {
    background: white;
    border-radius: 0 !important;
    

    border: 1px solid #151357;
    box-sizing: border-box;
  }

  .ant-input ::-webkit-input-placeholder,
  .ant-input :-ms-input-placeholder,
  .ant-input ::placeholder {
  }
`;


export {InputText, InputPasswordText};


