import {Backdrop, Paper} from "@mui/material";
import LOGO from "../Image/logo/logo.png";
import {FaArrowLeft} from "react-icons/fa";

const PopupGame = ({link, onClose}) => {
    return (
        <Backdrop
            sx={{color: '#fff', zIndex: 10}}
            open={true}
        >
            <Paper
                sx={{
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    zIndex: 4,
                    backgroundColor: "unset",
                    boxShadow: "unset"
                }}
                elevation={3}>
                <div style={{
                    backgroundColor: '#426ce8',
                    width: "100%",
                    maxWidth: "500px",
                    margin: "auto",
                    position: "relative"
                }}>
                    <FaArrowLeft
                        style={{left: "15px", top: "15px", position: "absolute", fontSize: "26px", color: '#FFFFFF'}}
                        onClick={onClose}/>

                    {/*<BsArrowLeftSquareFill*/}
                    {/*    onClick={onClose}*/}
                    {/*    style={{*/}
                    {/*        position: "absolute",*/}
                    {/*        left: "15px",*/}
                    {/*        top: "15px",*/}
                    {/*        fontSize: "40px",*/}
                    {/*        color: "red",*/}
                    {/*        cursor: "pointer"*/}
                    {/*    }}/>*/}
                    <div style={{display: "flex", justifyContent: "center", textAlign: 'center'}}>
                        <img style={{height: "50px", margin: "5px 0"}} src={LOGO} alt=""/>
                        <span style={{
                            marginTop: '20px',
                            fontSize: '20px',
                            marginLeft: '10px',
                            color: '#e5ae6d',
                            fontWeight: '900'
                        }}>KOREA 뉴스</span>
                    </div>
                </div>
            </Paper>
            <div
                style={{
                    marginTop: "60px",
                    width: "100%",
                    maxWidth: "500px",
                    backgroundColor: "black",
                    position: 'fixed',
                    height: 'calc(100% - 60px)'
                }} onClick={() => {
            }}>
                <iframe style={{width: "100%", height: '100%', border: "none"}} src={link}/>
            </div>
        </Backdrop>
    );
};

export default PopupGame;
