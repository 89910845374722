export const SUCCESS = {
  type: 'success',
  color: 'green',
};
export const INFO = {
  type: 'info',
  color: 'blue',
};
export const WARNING = {
  type: 'warning',
  color: 'yellow',
};
export const ERROR = {
  type: 'error',
  color: 'red',
};
