import {notification} from 'antd';
import React from 'react';

export const NotificationPopup = (message, type) => {
    notification[type.type]({
        message: <span
            style={{color: `${type.color}`}}>{type.type === "success" ? 'Thông báo' : type.type === "error" ? 'Thông báo' : 'Thông báo'}</span>,
        description: message,
        key: message,
        className: 'custom-class',
        style: {
            width: 'max-content',
            color: type.color,
            padding: '30 10px',
            zIndex: 1000
        },
        placement: 'bottomRight',
    });
};
