import styled from "styled-components";
import {Input} from "antd";

export const Container = styled.div`

  .react-datepicker-wrapper {
    width: 100%;

  }

  .react-datepicker__input-container {
    input {
      width: 100%;
      padding-left: 40px;
      font-size: 20px;
      border: 1px solid #ccc;
      border-radius: 4px;
      height: 40px;
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    margin: 6px 8px;
  }

  .menu {
    background-color: #ffb600;

    .row_menu {
      display: flex;

      .item_menu {
        cursor: pointer;
        padding: 4px 8px;
        margin: 6px 8px;
        //border-bottom: 1px solid #ffb600;
      }
    }
  }

  .row {
    margin: 12px 6px;

    .button_type {
      display: flex;
      flex-wrap: wrap;
      margin: 12px 0;

      .button_item {
        width: 30%;
        color: black;
        margin: 4px 4px;
      }
    }


    .list_function {
      margin: 12px 0;
      display: flex;

      .button_item_funtion {
        margin-right: 8px;
      }
    }

    .style_input {
      margin: 12px 0;

      ::placeholder {
        font-size: 12px !important;
      }
    }

    .style_text_total {
      ::placeholder {
        font-size: 12px !important;
      }
    }

  }

  .history {
    width: 90vw;
    margin: 12px auto;
  }

  .item_history {
    margin: 12px 0;
    height: max-content;
    border: 1px solid #ccc;
    padding: 12px;
    border-radius: 8px;

    .row_item {
      display: flex;
      overflow: hidden;
      justify-content: space-between;
      margin: 8px 0;

      div {
        width: 50%;
      }
    }
  }

  .response {
    margin: 8px;

    .response_row {
      width: 100%;

      .item_response {
        display: flex;
        margin: 12px 0;
        font-size: 20px;
      }

      .title_response {
        width: 120px;
      }

      .number_response {
        width: 100%;
        text-align: center;
        word-spacing: 16px;
        font-weight: bold;
      }
    }
  }
`


export const InputText = styled(Input)`
  background: white;
  box-sizing: border-box;
  border: 1px solid #151357;
  border-radius: 4px;


  &:where(.css-dev-only-do-not-override-ed5zg0) .ant-input-group .ant-input-group-addon {
    border-radius: 0;
  }


  .ant-input {
    border-radius: 0 !important;
  }

  ::-webkit-input-placeholder,
  :-ms-input-placeholder,
  ::placeholder {
  }
`;
