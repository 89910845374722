import React from 'react';
import {Backdrop} from '@mui/material';
import {PulseLoader} from 'react-spinners';


const GlobalBackdrop = ({isLoading}) => {

    return (
        <>
            <Backdrop
                sx={{color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 10}}
                open={true}
            >
                <PulseLoader color="#36d7b7"
                            style={{height: "100px"}}
                />
            </Backdrop>
        </>
    );
};

export default GlobalBackdrop;
