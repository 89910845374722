import styled from "styled-components";
import Slider from "react-slick";
import BACKGROUND_XO_SO from '../../Image/content/background_xo_so.png'


export const SliderCustom = styled(Slider)`
  overflow: hidden;

  .slick-dots {
    //display: none !important;
    bottom: 10px;
  }

  .slick-dots li button:before {
    color: white;
  }

  .slick-arrow, .slick-prev {
    display: none;
  }

  .slick-slider {
    //width: 100vw;
    //height: 100vh;
    //overflow: hidden;
  }
`

export const Container = styled.div`
  position: relative;
  width: 100vw;
  overflow-y: auto;
  @media (min-width: 500px) {
    width: 500px;
    margin: auto;
  }

  .header {
    background: linear-gradient(83deg, #3880e5, #759bff);
    height: 52px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding: 0 20px;

    .logo {
      cursor: pointer;
      height: 52px;
      width: auto;
    }

    .row_login {
      display: flex;
      color: white;
      font-weight: 500;

      div {
        cursor: pointer;
      }
    }
  }

  .menu_footer {
    position: fixed;
    bottom: 0;
    width: 100vw;
    overflow: hidden;
    padding: 8px 0;
    background-color: white;
    @media (min-width: 500px) {
      width: 500px;
      margin: auto;
    }

    .row_menu_footer {
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
    }

    .menu_footer_item {
      cursor: pointer;
      margin: 0 14px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
    }
  }

  .banner {
    img {
      width: 100vw;
      height: auto;
    }
  }

  .row_menu {
    display: flex;
    overflow: auto;

    .item_menu {
      text-align: center;
      border-radius: 4px;
      padding: 4px;
      margin: 4px 2px;
      border: 1px solid #ccc;

      img {
        width: 40px;
        height: auto;
      }

      div {
        font-size: 12px;
        font-weight: bold;
        width: 50px;
      }
    }
  }

  .title_content_game {
    margin: 12px;

    .header_content_game {
      margin-right: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .title_content_game {
      display: flex;
      align-items: center;

      img {
        height: 32px;
        margin-right: 12px;
      }

      div {
        font-weight: bold;
      }
    }

    .xem_them {
      font-size: 12px;
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }

  .row_ket_qua_xo_so {
    margin-left: 12px;
    display: flex;
    overflow: auto;
  }

  .item_ket_qua_xo_so {
    margin-right: 12px;
    min-width: 230px;
    min-height: 110px;
    background-image: url(${BACKGROUND_XO_SO});
    border-radius: 8px;
    position: relative;
    color: white;

    .handle_ket_qua_xo_so {
      position: absolute;
      bottom: 0;
      width: 100%;
      height: 36px;
      background-color: #528ded;
      border-bottom-left-radius: 8px;
      border-bottom-right-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;

      .button_cuoc {
        padding: 3px 6px;
        background-color: orange;
        border-radius: 8px;
        margin-right: 4px;
        text-shadow: white;
        cursor: pointer;
      }
    }
  }

  .row_casino {
    margin-left: 12px;
    margin-right: 12px;

    .item_casino {
      height: auto;
      min-height: 160px;
      width: 100%;
      background-size: cover;
      border-radius: 8px;
      position: relative;
      color: white;
      object-fit: fill;
      margin-bottom: 30px;
    }

    .button_cuoc {
      padding: 3px 6px;
      background-color: orange;
      border-radius: 8px;
      margin-right: 4px;
      text-shadow: white;
      width: max-content;
      cursor: pointer;
    }
  }

  .row_bong_da {
    margin-left: 12px;
    margin-right: 12px;

    .item_bong_da {
      height: auto;
      min-height: 160px;
      width: 100%;
      background-size: cover;
      border-radius: 8px;
      position: relative;
      color: white;
      margin-bottom: 30px;
    }

    .button_cuoc {
      padding: 3px 6px;
      background-color: orange;
      border-radius: 8px;
      margin-right: 4px;
      text-shadow: white;
      width: max-content;
      cursor: pointer;
    }
  }
;
`


export const DangKyNhap = styled.div`
  position: absolute;
  background-color: white;
  width: 100vw;
  overflow: hidden;
  padding-bottom: 72px;
  height: max-content;

  .header {
    display: flex;
    justify-content: center;
    background-color: #ccc;
    position: relative;

    img {
      width: 80px;
      cursor: pointer;
    }


  }

  .row_login {
    width: 360px;
    margin: 20px auto 0;

    .button_dang_nhap {
      width: 100%;
      text-align: center;
      padding: 12px;
      font-weight: bold;
      background-color: gray;
      color: white;
      margin: 20px 0;
      cursor: pointer;
    }

    .button_dang_ky {
      width: 100%;
      text-align: center;
      padding: 12px;
      font-weight: bold;
      background-color: gray;
      color: white;
      margin: 20px 0;
      cursor: pointer;
    }

  }

  .title {
    font-size: 24px;
    text-align: center;
    font-weight: 700;
    text-transform: uppercase;
  }

`


export const ModalCustom = styled.div`
  z-index: 2;
  position: absolute;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  top: 0;
  left: 0;
`

export const ContentCustom = styled.div`
  position: relative;
  width: 100vw;
  height: 100vh;
`

export const RowCustom = styled.div`
  position: absolute;
  width: 320px;
  padding: 20px 20px;
  background-color: white;
  color: black;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);


  .style_button {
    text-align: center;
    padding: 12px;
    font-weight: bold;
    background-color: gray;
    color: white;
    margin: 20px 0;
    cursor: pointer;
  }
`

export const StyleUser = styled.div`
  height: 100vh;
  width: 100vw;
  background-color: #6c6868;
  @media (min-width: 500px) {
    width: 500px;
    margin: auto;
  }

  .header {
    width: 100%;
    height: 80px;
  }

  .row_header {
    height: 60px;
    display: flex;
    margin: 20px;
    align-content: center;
    align-items: center;
  }

  .main {
    height: calc(100vh - 100px);
    width: 100%;
    background-color: white;
    border-top-right-radius: 32px;
    border-top-left-radius: 32px;
  }

  .item_show_coin {
    position: relative;
    margin-bottom: 12px;
    margin-right: 40px;
    display: flex;
    align-items: center;
    align-content: center;
    padding: 8px 12px 8px 8px;
    justify-content: space-between;
    border-radius: 12px;
    border: 1px solid #ccc;
  }


  .item_button_function {
    border: 1px solid #ccc;
    padding: 8px 2px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    align-content: center;
    align-items: center;
    justify-content: center;
    width: 90px;
    text-align: center;
    cursor: pointer;

    img {
      width: 60px;
    }

    div {
      font-weight: bold;
    }
  }

  .button_chuc_nang {
    position: relative;
    display: flex;
    cursor: pointer;
    align-items: center;
    align-content: center;
    padding: 4px;
    margin: 12px 0;
    border-bottom: 1px solid #ccc;

    img {
      width: 32px;
      margin-right: 20px;
    }

    div {
      font-weight: bold;
    }

    .icon {
      position: absolute;
      right: 10px;
      font-size: 24px;
    }
  }


`
